<template>
  <v-dialog
    v-model="showDialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
    @click:outside="cancel"
  >
    <v-form
      ref="form"
      method="post"
      action="/"
      lazy-validation
      @submit.prevent="sendMessage()"
    >
      <v-card>
        <v-toolbar
          dense
          flat
        >
          <v-toolbar-title class="text-body-2 font-weight-bold">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-show="!!message"
          class="mt-1"
        >
          {{ message }}
        </v-card-text>
        <v-card-text
          v-show="!!secondaryMessage"
          class="mt-1 text-center"
        >
          <h2>{{ secondaryMessage }}</h2>
        </v-card-text>
        <v-card-text>
          <label for="recipient">Or send this token to: </label>
          <v-radio-group
            v-model="form.recipient"
            column
          >
            <v-radio
              label="Customer's phone number"
              value="customer"
            ></v-radio>
            <v-radio
              label="Specified phone number"
              value="specified"
            ></v-radio>
          </v-radio-group>
          <v-expand-transition>
            <div v-show="form.recipient === 'specified'">
              <v-text-field
                v-model="form.phone_number"
                label="Phone number"
                placeholder="07********"
                outlined
              ></v-text-field>
              <small
                v-show="form.errors.has('phone_number')"
                class="validation-error"
              >{{
                form.errors.get('phone_number')
              }}</small>
            </div>
          </v-expand-transition>
          <v-textarea
            v-model="form.message"
            clearable
            label="Type your message here"
            outlined
            auto-grow
            :rules="message_rules"
            required
          ></v-textarea>
          <small
            v-show="form.errors.has('message')"
            class="validation-error"
          >{{
            form.errors.get('message')
          }}</small>
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            v-if="showCancelButton"
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click.native="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            :loading="form.busy"
            outlined
            type="submit"
          >
            {{ agreeText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import Form from 'vform'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    meterId: {
      type: String,
      default: null,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Confirm',
    },
    message: {
      type: String,
      default: 'Are you sure you want to delete this record?',
    },
    secondaryMessage: {
      type: String,
      default: null,
    },
    agreeText: {
      type: String,
      default: 'OK',
    },
  },
  data() {
    return {
      isSendMessageButtonLoading: false,
      options: {
        color: 'grey lighten-3',
        width: 400,
        zIndex: 200,
        noConfirm: false,
      },
      message_rules: [
        v => !!v || 'Message is required',
      ],
      form: new Form({
        meter_id: '',
        recipient: 'customer',
        phone_number: '',
        message: '',
      }),
    }
  },
  watch: {
    showDialog(val) {
      this.setDefaultMessage()
    },
    meterId(val) {
      this.setMeterId()
    },
  },
  mounted() {
    this.setDefaultMessage()
    this.setMeterId()
  },
  methods: {
    agree() {
      this.$emit('agree')
    },
    cancel() {
      this.$emit('cancel')
    },
    setDefaultMessage() {
      this.form.message = `To clear the meter token please input this token into the meter: ${this.secondaryMessage}`
    },
    setMeterId() {
      this.form.meter_id = this.meterId
    },
    sendMessage() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post('meter-send-clear-token-message')
          .then(() => {
            this.$notification.success('Message sent successfully')
            this.$emit('cancel')
          })
          .catch(error => {
            this.$notification.error(error.response.data.message)
          })
      }
    },
  },

}
</script>

<style>

</style>
